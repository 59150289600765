import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" keywords={[`gatsby`, `application`, `react`]} />
    <h1>About Psychohistory</h1>
    <p>Psychohistory is a quantum forecasting model developed by Hari Seldon. It uses antichaos theory mathematics to make the future knowable.</p>
    <p>Unless there are mules. We hate mules</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)
export default AboutPage
